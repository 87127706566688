.status__badge {
  padding: 2px 5px;
  color: white;
  border-radius: 4px;
  background-color: #313131;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 13px;
}
.status__badge.en_attente {
  background-color: #dca803;
}
.status__badge.désapprouvé {
  background-color: #c82d2d;
}
.status__badge.approuvé {
  background-color: #2e902e;
}

*:disabled {
  cursor: not-allowed !important;
  pointer-events: auto !important;
  opacity: 0.4;
}

.container__page {
  width: 100%;
  margin: 50px auto;
}

.container__inner {
  width: 100%;
  margin: 50px auto;
}
