.page__details__card_container {
  padding: 10px;
}

.refund__request__details__inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  align-content: center;

  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .refund__request__details__inner {
    grid-template-columns: 1fr;
  }
}
